import { IGetTemplateResponse } from '@dogado/webcard-shared';
import NextHead from 'next/head';
import { useSelector } from 'react-redux';
import { useConfig, useReseller, useStaticUrl } from '../lib/hooks';
import { getTemplatePreviewImage } from '../lib/utils';
import { IStore } from '../types';

type Props = {
    children: React.ReactNode;
};

function Head({ children }: Props) {
    let config = useConfig();
    let templates = useSelector<IStore, IGetTemplateResponse>((state) => state.templates || {});
    let staticUrl = useStaticUrl();

    let reseller = useReseller();

    return (
        <NextHead>
            {children}

            {Object.keys(templates).map(
                (template) =>
                    staticUrl && <link rel="preload" href={getTemplatePreviewImage(staticUrl, template)} as="image" key={template} />
            )}

            {config.tracking.hotjar.enabled && process.env.NODE_ENV === 'production' && (
                <script
                    dangerouslySetInnerHTML={{
                        __html: `(function(h,o,t,j,a,r){
                        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                        h._hjSettings={hjid:${config.tracking.hotjar.trackingId},hjsv:6};
                        a=o.getElementsByTagName('head')[0];
                        r=o.createElement('script');r.async=1;
                        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                        a.appendChild(r);
                    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`
                    }}
                ></script>
            )}
            {config.tracking.gtag.enabled && process.env.NODE_ENV === 'production' && (
                <>
                    <script async src={`https://www.googletagmanager.com/gtag/js?id=${config.tracking.gtag.trackingId}`}></script>
                    <script
                        dangerouslySetInnerHTML={{
                            __html: `
                            window.dataLayer = window.dataLayer || [];
                            function gtag(){dataLayer.push(arguments);}
                            gtag('js', new Date());
                            gtag('config', '${config.tracking.gtag.trackingId}', {
                                page_path: window.location.pathname,
                            });
                            `
                        }}
                    />
                </>
            )}
            {config.tracking.consent.enabled && process.env.NODE_ENV === 'production' && (
                <script src="https://cdn.stroeerdigitalgroup.de/metatag/live/SDG_ConsentOnly/metaTag.min.js"></script>
            )}

            {!!reseller && (
                <>
                    <link rel="apple-touch-icon" sizes="180x180" href={`/favicons/${reseller}/apple-touch-icon.png`} />
                    <link rel="icon" type="image/png" sizes="32x32" href={`/favicons/${reseller}/favicon-32x32.png`} />
                    <link rel="icon" type="image/png" sizes="16x16" href={`/favicons/${reseller}/favicon-16x16.png`} />
                    <link rel="manifest" href={`/favicons/${reseller}/site.webmanifest`} />
                    <link rel="mask-icon" href={`/favicons/${reseller}/safari-pinned-tab.svg`} color={config.ui.colors.primary} />
                    <link rel="shortcut icon" href={`/favicons/${reseller}/favicon.ico`} />
                    <meta name="msapplication-TileColor" content={config.ui.colors.primary} />
                    <meta name="msapplication-config" content={`/favicons/${reseller}/browserconfig.xml`} />
                    <meta name="theme-color" content="#ffffff" />
                </>
            )}
        </NextHead>
    );
}

export default Head;
